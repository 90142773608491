import { Textarea, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'
import { useFormContext } from 'react-hook-form'

export const T = styled(Textarea)`
  border-radius: 15px;
  font-size: 14px;
  padding: 15px 20px;
  background-color: #eeeeee;
  border: 0;
  min-height: 115px;
`

const TextareaField = React.forwardRef((props: any, ref: any) => {
  const { watch } = useFormContext()
  const maxLength = props.maxLength ?? 1000
  const field = watch(props.name)
  return (
    <>
      <T resize="vertical" ref={ref} {...props} />
      <Text fontSize="xs" textAlign="right" color={field?.length > maxLength ? 'red' : ''}>
        {field?.length} / {maxLength}
      </Text>
    </>
  )
})

export default TextareaField
