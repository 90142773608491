import * as yup from 'yup'

const schema = yup.object({
  firstName: yup.string().required('Inserisci il tuo nome'),
  lastName: yup.string().required('Inserisci il tuo cognome'),
  bioDescription: yup.string().required('Inserisci un testo che descriva qualcosa su di te'),
  gender: yup.string().required()
})

export type Schema = yup.InferType<typeof schema>
export default schema
