import { CreateToastFnReturn, useToast, UseToastOptions } from '@chakra-ui/react'
import { refreshUser } from '@features/auth/auth.actions'
import { useAppDispatch } from '@features/index'
import { useFormbitContext } from '@radicalbit/formbit'
import Api from '@src/services/api/api.service'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { Schema } from './schema'

const useHandleOnSubmit = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const toast = useToast()
  const { submitForm, isDirty, isFormInvalid } = useFormbitContext<Schema>()

  const isSubmitDisabled = !isDirty || isFormInvalid()

  const handleOnSubmit = useCallback(() => {
    submitForm(async ({ form }) => {
      if (isSubmitDisabled) {
        return false
      }
      try {
        const result = await Api.editUser(form)

        if (result && result.status === 200) {
          dispatch<any>(refreshUser())

          showSuccessNotification(toast)

          navigate('/profile', { replace: true })
        }
      } catch (e: any) {
        showErrorNotification(e, toast)
      }
    })
  }, [dispatch, isSubmitDisabled, navigate, submitForm, toast])

  return { handleOnSubmit, isSubmitDisabled }
}

const showErrorNotification = (e: any, toast: CreateToastFnReturn) => {
  const commonToast: UseToastOptions = {
    status: 'error',
    duration: 2500,
    isClosable: true,
    position: 'bottom',
  }

  if (e?.response?.data?.message) {
    toast({
      ...commonToast,
      title: e.response.data.message,
    })
  }
  if (typeof e?.response?.data === 'string') {
    toast({ ...commonToast, title: e.response.data })
  }
}

const showSuccessNotification = (toast: CreateToastFnReturn) => {
  toast({
    title: 'Profilo modificato con successo',
    status: 'success',
    duration: 2500,
    isClosable: true,
    position: 'bottom',
  })
}

export default useHandleOnSubmit
