import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea } from '@chakra-ui/react'
import { FormbitContextProvider, useFormbitContext } from '@radicalbit/formbit'
import { I } from 'components/shared/input/input.style'
import React, { useRef, useState } from 'react'
import { FaVolumeUp } from 'react-icons/fa'
import schema, { Schema } from './schema'
import useHandleOnSubmit from './use-handle-on-submit'
import { Capacitor } from '@capacitor/core'
import useAutosizeTextArea from '../../hooks/useAutosizeTextArea'

const isNative = Capacitor.isNativePlatform()

function Announcement() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleOnClick = () => {
    setIsModalOpen(true)
  }

  return (
    <Flex alignItems="center" gap=".75rem" onClick={handleOnClick} cursor="pointer">
      <Box color="brand.500" fontSize="1.5rem">
        <FaVolumeUp />
      </Box>

      <div>Scrivi in tutte le città</div>

      {isModalOpen && (
        <FormbitContextProvider<Schema> initialValues={{message:''}} schema={schema}>
          <AnnouncementModal setIsModalOpen={setIsModalOpen} />
        </FormbitContextProvider>
      )}
    </Flex>
  )
}

function AnnouncementModal({ setIsModalOpen }) {
  const {
    handleOnSubmit,
    args: { isLoading },
    isSubmitDisabled,
  } = useHandleOnSubmit(setIsModalOpen)

  const handleOnClose = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal isOpen onClose={handleOnClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody pb="40px">
          <InputText />
        </ModalBody>

        <ModalFooter>
          <Flex color="brand.500" cursor="pointer" fontSize="1.5rem" width="100%" justifyContent="center" paddingBottom="3rem">
            <Button borderRadius="10px" isDisabled={isSubmitDisabled} isLoading={isLoading} onClick={handleOnSubmit} type="button">
              Invia messaggio
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
function InputText() {
  const { write, error, form } = useFormbitContext<{message:string}>()
  const message = form?.message ?? ''

  const textAreaInputRef = useRef<HTMLTextAreaElement>(null)

  useAutosizeTextArea(textAreaInputRef.current, message)

  const handleOnInput = ({ currentTarget: { value } }) => {
    write('message',value)
  }

  const handleOnKeyDown = event => {
    if(isNative){
      nativeHandleOnKeyDown(event)
    }else{
      webHandleOnKeyDown(event)
    }
  }

  const nativeHandleOnKeyDown = (event) => {
    if(event.key === 'Enter') {
      event.preventDefault();
      write('message',`${message}\n`)
    }
  }

  const webHandleOnKeyDown = (event) => {
    if(event.shiftKey && event.key === 'Enter'){
      event.preventDefault();
      write('message',`${message}\n`)
    }
  }

  return (
    <Flex direction="column" gap=".5rem">
      <Flex direction="column" gap="1rem" width="100%">
        <Box fontWeight="500">Testo</Box>
        <Textarea
          ref={textAreaInputRef}
          resize="none"
          variant="solid"
          rows={1}
          placeholder="Inserisci il tuo annuncio"
          value={message}
          maxHeight="10rem"
          px="20px"
          py="15px"
          fontSize="14px"
          onInput={handleOnInput}
          onKeyDown={handleOnKeyDown}
      >
      </Textarea>
      </Flex>

      {error('message') && (
        <Text as="i" color="red">
          {error('message')}
        </Text>
      )}
    </Flex>
  )
}

export default Announcement
