import { Box, Divider, Flex, Text, useDisclosure, useToast } from '@chakra-ui/react'
import CompleteProfileModal from 'components/shared/complete-profile-modal/complete-profile-modal'
import { Alert, Confirm } from 'components/shared/confirm/confirm'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { selectorAuth } from 'features/auth/auth.slice'
import React from 'react'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Api from 'services/api/api.service'
import { Attendance } from 'typings/database'
import { genderMap } from '../../costants/genders'
import useGetEventById from '../chat/hooks/use-get-event-by-id'
import EventButtons from './event-buttons'
import EventCost from './event-cost'
import EventCover from './event-cover'
import EventDate from './event-date'
import EventLocation from './event-location'
import EventParticipants from './event-participants'
import EventParticipantsInfo from './event-participants-info'
import EventScreenHeader from './event-screen-header'

const EventScreen = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const { id } = useParams()

  const { user } = useSelector(selectorAuth)

  const { isLoading, error, data, refetch } = useGetEventById(
    { eventId: id },
    {
      onError: () => {
        toast({
          title: errorMessage,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      },
    },
  )

  const errorMessage = error?.message

  const attendances = data?.attendances
  const attendance = attendances && attendances.find((e: Attendance) => e.user._id === user._id)
  const isApproved = !!attendance?.approvedAt

  const organizer = data?.organizer
  const isOrganizer = user && organizer?._id === user._id

  const chatSid = data?.chatSid
  const title = data?.title

  const genderBalanceActive = data?.limitations?.genderBalanceActive
  const genderPartecipants = data?.limitations ? data?.limitations[user.gender + 'Participants'] : 0
  const genderBalanceCounts = data?.genderBalanceCounts ? data?.genderBalanceCounts[user.gender] : 0

  const eventCost = data?.cost
  const eventDescription = data?.description

  const { isOpen: isCompleteProfileOpen, onOpen: onCompleteProfileOpen, onClose: onCompleteProfileClose } = useDisclosure()

  const createAttendanceMutation = useMutation({
    mutationFn: async () => {
      try {
        const createAttendancePromise = Api.createAttendance(id as string)
        return createAttendancePromise
      } catch (e: any) {
        return toast({
          title: e?.response?.data?.message || 'Error',
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
    },
    onSuccess: () => {
      refetch()
      navigate(`/event/${chatSid}/chat`)
    },
  })

  const createAttendance = async () => {
    if (!user) {
      await Alert({ title: 'Non registrato', message: `Effettua l'accesso o registrati per partecipare all'evento`, confirmText: 'Continua' })
      navigate('/login')
      return
    }
    if (!user?.avatar) {
      onCompleteProfileOpen()
      return
    }
    if (genderBalanceActive && genderPartecipants - genderBalanceCounts <= 0) {
      const gender = genderMap.find(g => g[0] === user.gender) || ['', 'Sesso non specificato']
      await Alert({ title: 'Posti finiti', message: `I posti per ${gender[1].toLowerCase()} sono finiti`, confirmText: 'ok' })
      return
    }
    if (
      !(await Confirm({
        title: 'Conferma',
        message: `Conferma la tua partecipazione. In caso non potrai più partecipare, annullala per evitare ammonizioni.`,
      }))
    ) {
      return
    }

    createAttendanceMutation.mutate()
  }

  if (isLoading || !title) return <></>

  return (
    <>
      <Wrapper height="100%" overflowX="hidden">
        <EventScreenHeader
          isApproved={isApproved}
          event={data}
          isOrganizer={isOrganizer}
          attendance={attendance}
          navigate={navigate}
          id={id}
          refetch={refetch}
        />

        <EventCover event={data} attendance={attendance} navigate={navigate} createAttendance={createAttendance} isOrganizer={isOrganizer} />

        <Flex direction="column" gap="2rem">
          <Flex direction="column" gap="1rem">
            <EventLocation isOrganizer={isOrganizer} attendance={attendance} event={data} />
            <EventDate event={data} attendance={attendance} />
            {!!eventCost && <EventCost event={data} navigate={navigate} />}
          </Flex>

          {eventDescription && (
            <Text as="i" fontFamily="Poppins" fontWeight="400" fontSize="15px" textColor="rgb(70, 83, 98)">
              {eventDescription}
            </Text>
          )}

          <Divider borderColor="#465362" />

          <EventParticipantsInfo isOrganizer={isOrganizer} attendance={attendance} event={data} />

          <EventParticipants event={data} navigate={navigate} isOrganizer={isOrganizer} attendance={attendance} />

          <Box width="100%" height="2rem" />
          <EventButtons
            user={user}
            isOrganizer={isOrganizer}
            attendance={attendance}
            event={data}
            toast={toast}
            refetch={refetch}
            navigate={navigate}
            id={id}
            isApproved={isApproved}
            createAttendance={createAttendance}
          />
        </Flex>
      </Wrapper>

      <CompleteProfileModal isOpen={isCompleteProfileOpen} onClose={onCompleteProfileClose} />
    </>
  )
}

export default EventScreen
