import axios from 'axios'
import { useQuery } from 'react-query'
import { getRemoteConfig, getString } from 'firebase/remote-config'
import { REMOTE_CONFIG_PARAMS, firebaseApp } from '@src/services/firebase.service'

const useGetBannersQuery = () => {
  // Download https://docs.google.com/spreadsheets/d/e/2PACX-1vQC1JDDRuTTyPgMTAsdj_lOwdLymtCIkBkxzEXKeRjxxb-9QF9-0ZYPVwuKcLLRHk0WqoJ18jotsmJ2/pub?output=csv
  // using useQuery and axios
  // parse the csv and show the banner
  return useQuery('banner', async () => {
    const remoteConfig = getRemoteConfig(firebaseApp)
    const advBannerExcelUrlParams = getString(remoteConfig, REMOTE_CONFIG_PARAMS.ADV_BANNER_EXCEL_URL)

    const { data } = await axios.get(advBannerExcelUrlParams)

    // Parse dataBanner csv as array of objects
    // columns are NOME BRAND,ORIGINE FILE,LINK DESTINAZIONE,PROVINCIA,SCADENZA,COPY
    const parsed = data
      .split('\n')
      .map((row: string) => {
        const columns = row.split(',')

        return {
          nomeBrand: columns[0],
          origineFile: columns[1],
          linkDestinazione: columns[2],
          provincia: columns[3],
          scadenza: columns[4],
          copy: columns[5],
        }
      })
      .splice(1)

    return parsed
  })
}

export default useGetBannersQuery
