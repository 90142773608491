import { initializeApp } from 'firebase/app'
import { indexedDBLocalPersistence, initializeAuth } from 'firebase/auth'

export const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
})

export const auth = initializeAuth(firebaseApp, {
  persistence: indexedDBLocalPersistence,
})

export const getFirebaseAuth = () => {
  return auth
}

export const REMOTE_CONFIG_PARAMS = {
  CREATE_EVENT_CHATBOT: 'create_event_chatbot',
  ADV_BANNER_EXCEL_URL: 'adv_banner_excel_url',
}
