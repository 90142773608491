import { CreateToastFnReturn, useToast, UseToastOptions } from '@chakra-ui/react'
import { logoutAction } from '@features/auth/auth.actions'
import { Confirm } from '@src/components/shared/confirm/confirm'
import Api from '@src/services/api/api.service'
import { isIos } from '@src/utils/platform'
import { useNavigate } from 'react-router'

const useHandleOnDelete = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const handleOnDelete = async () => {
    if (!isIos()) {
      return window.open('https://sbam.social/delete-account', '_blank')
    }

    const confirmDelete = await Confirm({
      title: 'Elimina account',
      message: 'Sei sicuro di voler eliminare il tuo account?',
    })

    if (!confirmDelete) return

    try {
      await Api.deleteProfile()

      logoutAction()

      showSuccessNotification(toast)

      navigate('/login', { replace: true })
    } catch (e: any) {
      showErrorNotification(e, toast)
    }
  }

  return { handleOnDelete }
}

const showErrorNotification = (e: any, toast: CreateToastFnReturn) => {
  const commonToast: UseToastOptions = {
    status: 'error',
    duration: 2500,
    isClosable: true,
    position: 'bottom',
  }

  if (e?.response?.data?.message) {
    toast({
      ...commonToast,
      title: e.response.data.message,
    })
  }
  if (typeof e?.response?.data === 'string') {
    toast({ ...commonToast, title: e.response.data })
  }
}

const showSuccessNotification = (toast: CreateToastFnReturn) => {
  toast({
    title: 'Account eliminato con successo',
    status: 'success',
    duration: 2500,
    isClosable: true,
    position: 'bottom',
  })
}

export default useHandleOnDelete
