import React from 'react'
import { Text } from '@chakra-ui/react'

function TestingEnvText() {
  return (
    <Text fontWeight="600" textAlign="center">
      <Text color="red">⚠️ ATTENZIONE ⚠️</Text>
      <Text fontSize="small" textColor="white">
        Questa è la versione di beta testing dell'app, utile per provare le nuove funzionalità in anteprima.
      </Text>
      <Text fontSize="small" textColor="white">
        Per partecipare o organizzare eventi reali utilizza la versione non beta scaricabile dagli store o accessibile da{' '}
        <a href="https://app.sbam.social">
          <Text display="inline" textDecoration="underline">
            app.sbam.social
          </Text>
        </a>
        .
      </Text>
    </Text>
  )
}

export default TestingEnvText
