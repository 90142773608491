import { components } from './components'

export interface GenericModel {
  id: number
}
export type User = (typeof components)['schemas']['User'] | typeof GenericModel
export type Attendance = (typeof components)['schemas']['Attendance'] | typeof GenericModel
export type Event = (typeof components)['schemas']['Event'] | typeof GenericModel
export type Notification = (typeof components)['schemas']['Notification'] | typeof GenericModel
export type Category = (typeof components)['schemas']['Category'] | typeof GenericModel
export type Position = {
  lat: number
  lon: number
}
export type Location = (typeof components)['schemas']['Location'] | typeof GenericModel
export type Warning = (typeof components)['schemas']['Warning'] | typeof GenericModel
export enum WarningTypeModel {
  Admonition = 'Admonition',
  Ban = 'Ban',
}

export type EventDTO = components['schemas']['Event']
export type UserDTO = components['schemas']['User']
