import { Capacitor } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { setLoadingPosition, setPosition } from '@features/auth/auth.slice'
import { useSetLastPositionMutation } from '@hooks/use-set-last-position-mutation'
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { EnableGpsModalProps, LOCATION_PERMISSION_STATUS } from './types'

const isNative = Capacitor.isNativePlatform()

function CheckLocationPermission({ children }) {
  const [locationPermissionStatus, setLocationPermissionStatus] = useState<LOCATION_PERMISSION_STATUS>('RUN_REQUEST_PERMISSION')

  useCheckGpsPermission({ locationPermissionStatus, setLocationPermissionStatus })

  if (isNative && locationPermissionStatus === 'RUN_REQUEST_PERMISSION') {
    return <></>
  }

  if (isNative && locationPermissionStatus !== 'PERMISSION_GRANTED') {
    return <EnableGpsModal locationPermissionStatus={locationPermissionStatus} setLocationPermissionStatus={setLocationPermissionStatus} />
  }

  return children
}

function EnableGpsModal({ locationPermissionStatus, setLocationPermissionStatus }: EnableGpsModalProps) {
  const navigate = useNavigate()

  const isOpen = locationPermissionStatus === 'GPS_OFF' || locationPermissionStatus === 'LOCATION_PERMISSION_DENIED'

  const handleOnClose = () => {
    setLocationPermissionStatus('RUN_REQUEST_PERMISSION')
  }

  const handleOnGoToHomePage = () => {
    navigate('/')
  }

  const handleOnClick = async () => {
    try {
      switch (locationPermissionStatus) {
        case 'GPS_OFF': {
          await NativeSettings.open({
            optionAndroid: AndroidSettings.Location,
            optionIOS: IOSSettings.LocationServices,
          })

          break
        }

        case 'LOCATION_PERMISSION_DENIED': {
          await NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App,
          })

          break
        }

        default: // DO NOTHING
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnGoToHomePage} isCentered>
      <ModalOverlay />
      <ModalContent width="85%">
        <ModalHeader />
        <ModalCloseButton size="lg" />
        <ModalBody>
          <Flex direction="column" gap="1rem">
            <Flex direction="column" gap=".25rem">
              <Text fontWeight="600">Dove ti trovi?</Text>
              <Text>Per mostrarti la chat della città a te più vicina, abbiamo bisogno dei permessi di localizzazione.</Text>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button alignSelf="center" variant="ghost" size="sm" width="100%" borderRadius="10px" onClick={handleOnClose}>
            Ho Fatto
          </Button>

          <Button alignSelf="center" size="sm" width="100%" borderRadius="10px" onClick={handleOnClick}>
            Attiva il GPS
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const useCheckGpsPermission = ({ locationPermissionStatus, setLocationPermissionStatus }: EnableGpsModalProps) => {
  const dispatch = useDispatch()
  const { mutate, isLoading: isLoadingLastPosition, isSuccess } = useSetLastPositionMutation()

  useEffect(() => {
    async function checkGpsPermission() {
      if (!isNative) {
        return
      }

      if (locationPermissionStatus === 'RUN_REQUEST_PERMISSION') {
        try {
          const { location, coarseLocation } = await Geolocation.requestPermissions()

          if (coarseLocation === 'granted' || location === 'granted') {
            setLocationPermissionStatus('PERMISSION_GRANTED')

            const { coords } = await Geolocation.getCurrentPosition()
            const position = { lat: coords.latitude, lon: coords.longitude }

            dispatch(setPosition(position))
            dispatch(setLoadingPosition(false))

            if (!isSuccess && !isLoadingLastPosition) {
              mutate(position)
            }

            return
          }

          setLocationPermissionStatus('LOCATION_PERMISSION_DENIED')
        } catch (e) {
          console.error(e)
          setLocationPermissionStatus('GPS_OFF')
        }
      }
    }
    checkGpsPermission()
  }, [dispatch, isLoadingLastPosition, isSuccess, locationPermissionStatus, mutate, setLocationPermissionStatus])
}

export default CheckLocationPermission
