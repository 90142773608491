import { ReactComponent as BoltsIcon } from '@assets/bolts.svg'
import { ReactComponent as CloseIcon } from '@assets/close-icon.svg'
import { ReactComponent as PlusIcon } from '@assets/plus.svg'
import { ReactComponent as UserIcon } from '@assets/user.svg'
import { Clipboard } from '@capacitor/clipboard'
import { useDisclosure } from '@chakra-ui/hooks'
import { Avatar, Box, Button, Collapse, Flex, HStack, Icon, Text, useToast } from '@chakra-ui/react'

import { ReactComponent as CalendarIcon } from '@assets/calendar-icon.svg'
import { ReactComponent as GroupIcon } from '@assets/group-icon.svg'
import { ReactComponent as WarningIcon } from '@assets/warning-icon.svg'
import { ReactComponent as AdmonitionCard } from 'assets/admonition-card.svg'

import { ReactComponent as BanCard } from '@assets/ban-card.svg'
import { ReactComponent as ChatIcon } from '@assets/chat.svg'
import { ReactComponent as ReferralIcon } from '@assets/referral-logo.svg'
import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'
import CompleteProfileModal from '@components/shared/complete-profile-modal/complete-profile-modal'
import EventPreview from '@components/shared/event-preview/event-preview.component'
import { AdmonitionInfoModal } from '@components/shared/warning-info-modals/admonition-info-modal'
import { BanInfoModal } from '@components/shared/warning-info-modals/ban-info-modal'
import Wrapper from '@components/shared/wrapper/wrapper.component'
import { AdvBanner } from '@shared/adv-banner'
import { selectorAuth } from 'features/auth/auth.slice'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { IoMdShare } from 'react-icons/io'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Api from 'services/api/api.service'
import { Event, Warning, WarningTypeModel } from 'typings/database.d'
import { isIos } from 'utils/platform'
import ProfileMenu from './profile-menu'

type UserStatus = undefined | WarningTypeModel.Admonition | WarningTypeModel.Ban

function NewProfileScreen() {
  const navigate = useNavigate()
  const { user } = useSelector(selectorAuth)
  const toast = useToast()
  const { id } = useParams<{ id?: string }>()
  const profileId = id || user._id
  const isMe = profileId === user._id
  const [page, setPage] = useState('pastEvents')
  const scrollToRef = useRef<null | HTMLDivElement>(null)
  const {
    isLoading: eventsIsLoading,
    error: eventsError,
    data: eventsData,
  } = useQuery(
    isMe ? 'currentUserDoneEvents' : 'userDoneEvents#' + profileId,
    isMe ? () => Api.currentUserDoneEvents() : () => Api.userDoneEvents(profileId),
  ) as {
    error: String | any
    isLoading: boolean
    data: any
  }

  const {
    isLoading: profileIsLoading,
    error: profileError,
    data: profileData,
    refetch: profileRefetch,
  } = useQuery('profile' + profileId, () => Api.profile(profileId)) as {
    error: String | any
    isLoading: boolean
    data: any
    refetch: Function
  }

  const {
    isLoading: followersIsLoading,
    error: followersError,
    data: followersData,
    refetch: followersRefetch,
  } = useQuery('followers', isMe ? () => Api.currentFollowings() : () => Api.getUserFollowings(profileId)) as {
    error: String | any
    isLoading: boolean
    data: any
    refetch: Function
  }

  const error = profileError || eventsError || followersError

  const { isOpen: isDisclosureOpen, onToggle: onDisclosureToggle } = useDisclosure()
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()
  const { isOpen: isMenuOpen, onOpen: onMenuOpen, onClose: onMenuClose } = useDisclosure()
  const { isOpen: isAdmonitionInfoOpen, onOpen: onAdmonitionInfoOpen, onClose: onAdmonitionInfoClose } = useDisclosure()
  const { isOpen: isBanInfoOpen, onOpen: onBanInfoOpen, onClose: onBanInfoClose } = useDisclosure()

  const iFollowUserObj = !isMe ? profileData?.data?.following ?? null : null
  const userFollowsMeObj = !isMe ? profileData?.data?.follower ?? null : null
  const iSentRequestObj = !isMe ? profileData?.data?.followingRequestSent ?? null : null
  const userSentRequestObj = !isMe ? profileData?.data?.followingRequestReceived ?? null : null

  useEffect(() => {
    if (error && typeof error == 'string') {
      toast({
        title: error,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    } else if (error && typeof error?.message == 'string') {
      toast({
        title: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    }
  }, [error, toast])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const isLoading = eventsIsLoading || profileIsLoading || followersIsLoading
  const events = page === 'pastEvents' ? eventsData?.data.past : eventsData?.data.future
  const profile = profileData?.data

  if (!profile) return null

  const activeWarning = (profile.warnings as Warning[] | undefined)?.find(w => new Date(w.expiresAt).getTime() > Date.now())
  const userStatus: UserStatus = activeWarning ? activeWarning.type : undefined

  const shareProfile = async () => {
    if (!Capacitor.isNativePlatform()) {
      await Clipboard.write({ url: `${process.env.REACT_APP_APP_URL}/profile/${profileId}` })
      toast({
        title: 'URL profilo copiato in clipboard',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      })
    } else {
      await Share.share({
        dialogTitle: 'Condividi il tuo profilo sui social',
        title: `${profile.firstName} ${profile.lastName}`,
        text: '',
        url: `${process.env.REACT_APP_APP_URL}/profile/${profileId}`,
      })
    }
  }

  const sendFollowingRequest = async () => {
    try {
      await Api.sendFollowingRequest({ data: { userId: profile?._id } })
      followersRefetch()
      profileRefetch()
      toast({
        title: 'Richiesta di following inviata',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      })
    } catch (e: any) {
      toast({
        title: e?.response?.data?.message || 'Error',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      })
    }
  }

  const cancelFollowingRequest = async () => {
    try {
      await Api.cancelFollowingRequest(iSentRequestObj?._id)
      followersRefetch()
      profileRefetch()
      toast({
        title: 'Richiesta di following annullata',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      })
    } catch (e: any) {
      toast({
        title: e.response.data.message || 'Error',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      })
    }
  }

  const declineFollowingRequest = async () => {
    try {
      await Api.declineFollowingRequest(userSentRequestObj?._id)
      followersRefetch()
      profileRefetch()
      toast({
        title: 'Richiesta di following rifiutata',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      })
    } catch (e: any) {
      toast({
        title: e.response.data.message || 'Error',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      })
    }
  }

  const acceptFollowingRequest = async () => {
    try {
      await Api.acceptFollowingRequest(userSentRequestObj?._id)
      followersRefetch()
      profileRefetch()
      toast({
        title: 'Richiesta di following accettata',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      })
    } catch (e: any) {
      toast({
        title: e.response.data.message || 'Error',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      })
    }
  }

  const deleteFollowing = async doIFollowUser => {
    try {
      await Api.deleteFollowing(doIFollowUser ? iFollowUserObj?._id : userFollowsMeObj?._id)
      followersRefetch()
      profileRefetch()
      toast({
        title: doIFollowUser ? 'Non segui più questo utente' : 'Utente rimosso dai tuoi follower',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      })
    } catch (e: any) {
      toast({
        title: e.response.data.message || 'Error',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      })
    }
  }

  const goToFollowers = () => {
    if (iFollowUserObj || isMe) {
      navigate(isMe ? '/profile/followers/follower' : `/profile/${profileId}/followers/follower`)
    } else {
      toast({
        title: "Devi seguire l'utente per vedere i suoi follower",
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'bottom',
      })
    }
  }

  const goToFollowings = () => {
    if (iFollowUserObj || isMe) {
      navigate(isMe ? '/profile/followers/seguiti' : `/profile/${profileId}/followers/seguiti`)
    } else {
      toast({
        title: "Devi seguire l'utente per vedere i suoi utenti seguiti",
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'bottom',
      })
    }
  }

  const goToEvents = () => {
    if (iFollowUserObj || isMe) {
      if (scrollToRef.current) {
        const absoluteTop = scrollToRef.current.getBoundingClientRect().top + window.scrollY
        const headerHeight = 60

        window.scrollTo({ top: absoluteTop - headerHeight - 5, behavior: 'smooth' })
      }
    } else {
      toast({
        title: "Devi seguire l'utente per vedere i suoi eventi",
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'bottom',
      })
    }
  }

  const onOrganizeEvent = async () => {
    if ((isIos() || (user?.phoneVerified && user?.phone)) && user?.avatar) {
      navigate('/event/create/wizard')
    } else {
      onModalOpen()
    }
  }

  return (
    <Wrapper pt="20px" pb="90px" overflow-y="auto" overflow-x="hidden" gap="12px">
      <Flex justifyContent="space-between" pl="20px" pr="20px">
        <Box position="relative" onClick={shareProfile} cursor="pointer">
          <Icon boxSize="1.6em" position="relative" as={IoMdShare} _hover={{ color: 'brand.500' }} />
        </Box>

        <Box position="relative">
          <Avatar src={profile?.avatar} size="xl" borderWidth="3px" borderColor="brand.500" />
          {profile?.role && (
            <Flex backgroundColor="black" color="white" borderRadius=".5rem" justifyContent="center" position="absolute" width="100%" top="85%">
              <Text fontWeight="bold" fontSize="sm">
                {profile?.role}
              </Text>
            </Flex>
          )}
        </Box>

        <Box>
          {isMe && (
            <Box onClick={onMenuOpen} cursor="pointer">
              <Icon boxSize="1.6em" as={GiHamburgerMenu} _hover={{ color: 'brand.500' }} />
            </Box>
          )}
          {!isMe && (
            <Box
              cursor="pointer"
              top="0"
              right="0"
              onClick={() => {
                window.open('https://sbam.social/supporto', '_blank')
              }}>
              <Icon boxSize="1.6em" as={WarningIcon} _hover={{ color: 'brand.500' }} />
            </Box>
          )}
        </Box>
      </Flex>

      <Box>
        <Text align="center" fontWeight="semibold" fontSize="lg">
          {profile?.firstName} {profile?.lastName}{' '}
          {userStatus === WarningTypeModel.Admonition && (
            <Icon onClick={() => (isMe ? navigate('/monition') : onAdmonitionInfoOpen())} as={AdmonitionCard} />
          )}
          {userStatus === WarningTypeModel.Ban && <Icon onClick={() => (isMe ? navigate('/ban') : onBanInfoOpen())} as={BanCard} />}
        </Text>
        <Text align="center">{profile?.bioDescription}</Text>
      </Box>

      <Flex justifyContent="space-between" alignItems="center" pl="30px" pr="30px">
        <Box>
          <Text align="center" cursor="pointer" fontWeight="semibold" fontSize="xl" _hover={{ color: 'brand.500' }} onClick={goToFollowers}>
            {followersData?.data?.followers?.length}
          </Text>
          <Text align="center" textColor="#808C9A">
            Follower
          </Text>
        </Box>
        <Box>
          <Text cursor="pointer" align="center" fontWeight="semibold" fontSize="xl" _hover={{ color: 'brand.500' }} onClick={goToFollowings}>
            {followersData?.data?.followings?.length}
          </Text>
          <Text align="center" textColor="#808C9A">
            Seguiti
          </Text>
        </Box>
        <Box>
          <Text align="center" fontWeight="semibold" fontSize="xl" _hover={{ color: 'brand.500' }} cursor="pointer" onClick={goToEvents}>
            {eventsData?.data?.past?.length + eventsData?.data?.future?.length}
          </Text>
          <Text align="center" textColor="#808C9A">
            Eventi
          </Text>
        </Box>
      </Flex>

      <AdvBanner />

      {isMe && (
        <Button
          bgColor="brand.500"
          height="38px"
          leftIcon={
            <Box mr="1" mt="0.5" position="relative">
              <Icon boxSize="5" as={ReferralIcon} />
              <Icon boxSize="5" as={BoltsIcon} position="absolute" bottom="2" left="13px" />
            </Box>
          }
          onClick={() => {
            navigate('/referral')
          }}>
          <Text color="white" fontSize="16px">
            Invita amici
          </Text>
        </Button>
      )}

      {!profileError && !isMe && !iFollowUserObj && !userFollowsMeObj && !iSentRequestObj && !userSentRequestObj && (
        <Button
          bgColor="brand.500"
          height="38px"
          leftIcon={
            <Box mt="1">
              <Icon boxSize="5" as={UserIcon} mr="-1" />
              <Icon boxSize="4" as={PlusIcon} mb="1" />
            </Box>
          }
          onClick={sendFollowingRequest}>
          <Text color="white" fontSize="16px">
            Segui profilo
          </Text>
        </Button>
      )}

      {!profileError && !isMe && !iFollowUserObj && !userFollowsMeObj && iSentRequestObj && !userSentRequestObj && (
        <Button
          variant="outline_white"
          height="38px"
          leftIcon={
            <Box mt="1">
              <Icon color="brand.500" boxSize="5" as={UserIcon} mr="-1" />
              <Icon color="brand.500" boxSize="4" as={PlusIcon} mb="1" transform="rotate(45deg)" />
            </Box>
          }
          onClick={cancelFollowingRequest}>
          <Text color="brand.500" fontSize="16px">
            Annulla richiesta
          </Text>
        </Button>
      )}

      {!profileError && !isMe && !userFollowsMeObj && !iSentRequestObj && userSentRequestObj && (
        <HStack spacing="2">
          <Button
            variant="outline_white"
            height="38px"
            leftIcon={
              <Box mr="-1" mt="0.5">
                <Icon boxSize="5" as={PlusIcon} transform="rotate(45deg)" />
              </Box>
            }
            onClick={declineFollowingRequest}>
            <Text color="brand.500" fontSize="16px">
              Rifiuta richiesta
            </Text>
          </Button>
          <Button
            bgColor="brand.500"
            height="38px"
            leftIcon={
              <Box mr="-1" mt="0.5">
                <Icon boxSize="5" as={PlusIcon} />
              </Box>
            }
            onClick={acceptFollowingRequest}>
            <Text color="white" fontSize="16px">
              Accetta richiesta
            </Text>
          </Button>
        </HStack>
      )}

      {!profileError && !isMe && !iFollowUserObj && userFollowsMeObj && !userSentRequestObj && (
        <HStack spacing="1">
          <Button
            variant="outline_white"
            height="38px"
            leftIcon={
              <Box>
                <Icon boxSize="5" as={UserIcon} mr="-1" />
                <Icon boxSize="4" as={PlusIcon} mb="1" transform="rotate(45deg)" />
              </Box>
            }
            onClick={() => {
              deleteFollowing(false)
            }}>
            <Text color="brand.500" fontSize="12px">
              Rimuovi follower
            </Text>
          </Button>
          {iSentRequestObj && (
            <Button
              variant="solid"
              height="38px"
              leftIcon={
                <Box>
                  <Icon color="white" boxSize="5" as={UserIcon} mr="-1" />
                  <Icon color="white" boxSize="4" as={PlusIcon} mb="1" transform="rotate(45deg)" />
                </Box>
              }
              onClick={cancelFollowingRequest}>
              <Text color="white" fontSize="12px">
                Annulla richiesta
              </Text>
            </Button>
          )}
          {!iSentRequestObj && (
            <Button
              bgColor="brand.500"
              height="38px"
              leftIcon={
                <Box>
                  <Icon boxSize="5" as={UserIcon} mr="-1" />
                  <Icon boxSize="4" as={PlusIcon} mb="1" />
                </Box>
              }
              onClick={sendFollowingRequest}>
              <Text color="white" fontSize="12px">
                Segui anche tu
              </Text>
            </Button>
          )}
        </HStack>
      )}

      {!profileError && !isMe && iFollowUserObj && userFollowsMeObj && (
        <>
          <HStack width="100%" spacing="1">
            <Button variant="outline_white" height="38px" onClick={onDisclosureToggle} width="20%">
              <Flex direction="row" align="center" justify="center">
                <Icon boxSize="5" as={UserIcon} mr="-1" />
                <Icon boxSize="4" as={PlusIcon} mb="1" transform="rotate(45deg)" />
              </Flex>
            </Button>
            <Button
              bgColor="brand.500"
              height="38px"
              leftIcon={<Icon color="white" boxSize="5" as={ChatIcon} />}
              onClick={() => {
                navigate(`/event/${iFollowUserObj.chatSid}/chat`)
              }}
              width="80%">
              <Text color="white" fontSize="15px">
                Invia messaggio
              </Text>
            </Button>
          </HStack>
          <Collapse in={isDisclosureOpen} animateOpacity>
            <Box position="relative" padding="3" borderStyle="solid" borderWidth="1px" borderColor="black" borderRadius="4px">
              <Box position="absolute" top="5px" right="5px">
                <Icon as={CloseIcon} onClick={onDisclosureToggle} />
              </Box>
              <Flex direction="column">
                <Text
                  align="left"
                  mb="3"
                  _hover={{ textDecoration: 'underline' }}
                  onClick={() => {
                    deleteFollowing(false)
                  }}>
                  Rimuovi follower
                </Text>
                <Text
                  align="left"
                  _hover={{ textDecoration: 'underline' }}
                  onClick={() => {
                    deleteFollowing(true)
                  }}>
                  Smetti di seguire
                </Text>
              </Flex>
            </Box>
          </Collapse>
        </>
      )}

      {!profileError && !isMe && iFollowUserObj && !userFollowsMeObj && !iSentRequestObj && !userSentRequestObj && (
        <Button
          variant="outline_white"
          height="38px"
          leftIcon={
            <Box>
              <Icon boxSize="5" as={UserIcon} mr="-1" />
              <Icon boxSize="4" as={PlusIcon} mb="1" transform="rotate(45deg)" />
            </Box>
          }
          onClick={() => {
            deleteFollowing(true)
          }}>
          <Text color="brand.500" fontSize="16px">
            Non seguire più
          </Text>
        </Button>
      )}

      {(isMe || (!isMe && iFollowUserObj)) && (
        <Flex justify="space-around" textAlign="center" scrollBehavior="smooth" ref={scrollToRef} mt="12px">
          <Text
            fontSize="18px"
            cursor="pointer"
            onClick={() => setPage('pastEvents')}
            opacity={page === 'pastEvents' ? 1 : 0.5}
            fontWeight="semibold">
            Passati
          </Text>
          <Text
            fontSize="18px"
            cursor="pointer"
            onClick={() => setPage('futureEvents')}
            opacity={page === 'futureEvents' ? 1 : 0.5}
            fontWeight="semibold">
            Futuri
          </Text>
        </Flex>
      )}

      {!isMe && !iFollowUserObj && (
        <Box>
          <Text textAlign="center">Puoi vedere follower, seguiti e eventi solo di chi ha accettato la tua richiesta di poterlə seguire.</Text>
          <Text textAlign="center">Puoi chattare in privato con una persona solo se entrambi vi seguite a vicenda.</Text>
        </Box>
      )}
      {isLoading && <Text>Caricamento...</Text>}
      {events && !events.length && isMe && (
        <Text textAlign="center">
          {page === 'pastEvents'
            ? 'Gli eventi a cui hai partecipato o che hai organizzato appariranno qui.'
            : 'Gli eventi a cui parteciperai o che organizzerai appariranno qui.'}
        </Text>
      )}
      {events && !events.length && !isMe && iFollowUserObj && (
        <Text textAlign="center">
          {page === 'pastEvents'
            ? "Gli eventi a cui l'utente ha partecipato o che ha organizzato appariranno qui."
            : "Gli eventi a cui l'utente parteciperà o che organizzerà appariranno qui."}
        </Text>
      )}
      <Flex flex={1} direction="column" gap=".8rem">
        {!error && (isMe || (!isMe && iFollowUserObj)) && (
          <>
            {events &&
              events.map((event: Event, index: number) => (
                <Fragment key={event._id}>
                  <Link to={'/event/' + event._id}>
                    <EventPreview {...event} />
                  </Link>
                  {index > 0 && (index + 1) % 3 === 0 && <AdvBanner key={event._id + '_banner'} />}
                </Fragment>
              ))}
          </>
        )}
      </Flex>
      {events && !events.length && isMe && (
        <Flex justify="space-around" alignContent="center" mt="20px" gap={2}>
          <Link to="/">
            <Button
              variant="outline_white"
              height="35px"
              leftIcon={
                <Box position="relative">
                  <Icon as={CalendarIcon} />
                  <Icon as={GroupIcon} boxSize="10px" position="absolute" left="5px" top="7px" />
                </Box>
              }>
              <Text fontSize="17px">Partecipa</Text>
            </Button>
          </Link>
          <Button
            bgColor="brand.500"
            height="35px"
            onClick={onOrganizeEvent}
            leftIcon={
              <Box position="relative">
                <Icon as={CalendarIcon} color="white" />
                <Icon as={PlusIcon} boxSize="10px" position="absolute" left="5px" top="8px" />
              </Box>
            }>
            <Text fontSize="17px">Organizza</Text>
          </Button>
        </Flex>
      )}
      <CompleteProfileModal isOpen={isModalOpen} onClose={onModalClose} />
      <ProfileMenu isOpen={isMenuOpen} onClose={onMenuClose} />
      <AdmonitionInfoModal isOpen={isAdmonitionInfoOpen} onClose={onAdmonitionInfoClose} />
      <BanInfoModal isOpen={isBanInfoOpen} onClose={onBanInfoClose} />
    </Wrapper>
  )
}

export default NewProfileScreen
