import { FirebaseAnalytics } from '@capacitor-firebase/analytics'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { StatusBar, Style } from '@capacitor/status-bar'
import AuthGate from '@components/hocs/auth-route.component'
import Footer from '@components/layout/footer/footer'
import Header from '@components/layout/header/header'
import { WhiteBG } from '@components/layout/white-bg'
import { useFCM } from '@hooks/use-fcm'
import useFetchAndActivateRemoteConfig from '@hooks/use-fetch-and-activate-remote-config'
import { useSetGARoutes } from '@hooks/use-set-ga-routes'
import useUTMTracking from '@hooks/use-utm-tracking'
import AccessScreen from '@screens/access/access.screen'
import BanScreen from '@screens/ban/ban'
import CategoriesScreen from '@screens/categories/categories.screen'
import ChatScreen from '@screens/chat/chat.screen'
import CreateEventChatBot from '@screens/chat/create-event-chat-bot'
import ChatListScreen from '@screens/chatlist/chat-list-screen'
import { ConfirmSignup } from '@screens/confirm-signup/confirm-signup.screen'
import CreateEventScreen from '@screens/create-event/create-event.screen'
import EventScreen from '@screens/event/event.screen'
import EventsScreen from '@screens/events/events.screen'
import FollowersScreen from '@screens/followers/followers.screen'
import IntroScreen from '@screens/intro/intro'
import LoginScreen from '@screens/login/login.screen'
import MonitionScreen from '@screens/monition/monition'
import NotificationsScreen from '@screens/notifications/notifications.screen'
import NewProfileScreen from '@screens/profile/newprofile.screen'
import ReferralScreen from '@screens/referral/referral'
import { ForgotPassword } from '@screens/reset-password/forgot-password.screen'
import { PasswordReset } from '@screens/reset-password/reset-password.screen'
import SettingsScreen from '@screens/settings/settings.screen'
import ShopScreen from '@screens/shop/shop.screen'
import ConfirmNumberScreen from '@screens/signup/confirm-number.screen'
import PreferredCategoriesScreen from '@screens/signup/preferred-categories.screen'
import SignupScreen from '@screens/signup/signup.screen'
import * as Sentry from '@sentry/capacitor'
import { AppContext } from 'app.context'
import { BranchDeepLinks, BranchInitEvent } from 'capacitor-branch-deep-links'
import { User } from 'firebase/auth'
import moment from 'moment'
import 'moment/locale/it'
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import SmartBanner from 'react-smartbanner'
import 'react-smartbanner/dist/main.css'
import { auth } from 'services/firebase.service'
import { CSSGlobal } from 'theme'
import { isIos } from 'utils/platform'
import { iosTracking, tracking } from 'utils/tracking.util'
import packageJson from '../package.json'
import './app.css'
import { useAppDispatch } from './features'
import { loginAction, logoutAction } from './features/auth/auth.actions'
import { selectorAuth, setAuthState, setFirebaseUser, setIsRegistered, setLoading } from './features/auth/auth.slice'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://173860c6139344dc8d93f7e00ecd6362@o4504825505054720.ingest.sentry.io/4504825510494208',
    release: 'sbam-app@' + packageJson.version,
    dist: packageJson.version,
  })
}

moment.locale('it')

const App = () => {
  const dispatch = useAppDispatch()
  const { isAuthenticated, user, isLoading, isRegistered } = useSelector(selectorAuth)
  const { client, conversationsWithUnreadMessages, setConversationsWithUnreadMessages } = useFCM()
  const navigate = useNavigate()

  useUTMTracking()
  useSetGARoutes()

  const remoteConfigData = useFetchAndActivateRemoteConfig()
  const isLoadingRemoteConfig = remoteConfigData.isIdle || remoteConfigData.isLoading

  useEffect(() => {
    if (!isLoading && isRegistered && isAuthenticated && user) {
      // Track user
      if (user.email) {
        if (isIos()) {
          iosTracking(user.email)
        } else {
          tracking(user.email)
        }
      }
    }
  }, [isAuthenticated, isLoading, isRegistered, user])

  const logout = useCallback(() => {
    dispatch<any>(setLoading(false))
    dispatch<any>(logoutAction())
    setConversationsWithUnreadMessages([])
  }, [dispatch, setConversationsWithUnreadMessages])

  const handleWebAuthStateChange = useCallback(
    async (user: User | null) => {
      if (user) {
        dispatch(setFirebaseUser(user))

        try {
          const { claims } = await user.getIdTokenResult(true)
          const isRegistered = !!claims.sbamRegistered
          if (!isRegistered) {
            dispatch(setIsRegistered(false))
            dispatch(setAuthState(true))
            dispatch(setLoading(false))
          } else {
            dispatch<any>(loginAction())
          }
        } catch (error) {
          console.error('Failed to retrieve ID token:', error)
          logout()
        }
      } else {
        logout()
      }
    },
    [dispatch, logout],
  )

  useEffect(() => {
    FirebaseAnalytics.setEnabled({
      enabled: true,
    })

    /* Handle session expiration */
    auth.onAuthStateChanged(handleWebAuthStateChange)

    /* Set Status bar color */
    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({ style: Style.Light })
    }

    /* Hide the splash screen when the device is ready */
    const onDeviceReady = () => {
      SplashScreen.hide()
    }
    document.addEventListener('deviceready', onDeviceReady, false)

    // Deep linking
    CapacitorApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const url = new URL(event.url)
      const slug = event.url.split(url.origin).pop()
      if (slug && url.origin === process.env.REACT_APP_APP_URL) {
        navigate(slug)
      } else {
        navigate('/')
      }
    })

    CapacitorApp.addListener('appStateChange', ({ isActive }) => {
      if (isActive && Capacitor.isNativePlatform()) {
        FirebaseMessaging.removeAllDeliveredNotifications()
      }
    })
  }, [dispatch, navigate, logout, handleWebAuthStateChange])

  useEffect(() => {
    BranchDeepLinks.addListener('init', (event: BranchInitEvent) => {
      // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
      // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
      console.log('[BranchDeepLinks/Init]', JSON.stringify(event.referringParams))
    })

    BranchDeepLinks.addListener('initError', (error: any) => {
      console.error('[BranchDeepLinks/InitError]', JSON.stringify(error))
    })
  }, [])

  const getContext = () => ({
    client,
    conversationsWithUnreadMessages,
    setConversationAsRead: async conversationSid => {
      setConversationsWithUnreadMessages(prev => prev.filter(sid => sid !== conversationSid))
    },
    resetUnreadMessages: () => setConversationsWithUnreadMessages([]),
  })

  const isIntro = window.location.pathname === '/intro' || window.location.pathname === '/welcome'
  const needsBanner = Capacitor.getPlatform() === 'web' && !isIntro

  if (isLoadingRemoteConfig) {
    return <></> // TODO: add loader
  }

  return (
    <AppContext.Provider value={getContext()}>
      <CSSGlobal />
      {needsBanner && <SmartBanner />}
      <WhiteBG />
      {!isLoading && (
        <Routes>
          <Route path="/confirmRegistration" element={<ConfirmSignup />} />

          <Route path="/signup" element={<SignupScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/access" element={<AccessScreen />} />

          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/passwordReset" element={<PasswordReset />} />

          <Route
            path="/intro"
            element={
              <AuthGate>
                <IntroScreen />
              </AuthGate>
            }
          />
          <Route
            path="/welcome"
            element={
              <AuthGate>
                <IntroScreen />
              </AuthGate>
            }
          />
          <Route
            path="/confirm-number"
            element={
              <AuthGate>
                <ConfirmNumberScreen />
              </AuthGate>
            }
          />
          <Route
            path="/preferred-categories"
            element={
              <AuthGate>
                <PreferredCategoriesScreen />
              </AuthGate>
            }
          />

          <Route
            path="/"
            element={
              <AuthGate>
                <Header />
                <EventsScreen />
                <Footer />
              </AuthGate>
            }
          />

          <Route path="/event/create/:createEventMode" element={<CreateEventSwitch />} />

          <Route
            path="/event/:id/edit/"
            element={
              <AuthGate>
                <CreateEventScreen />
              </AuthGate>
            }
          />
          <Route
            path="/profile/followers/:tab"
            element={
              <AuthGate>
                <FollowersScreen />
              </AuthGate>
            }
          />
          <Route
            path="/profile/:id/followers/:tab"
            element={
              <AuthGate>
                <FollowersScreen />
              </AuthGate>
            }
          />
          <Route
            path="/chats/:page"
            element={
              <AuthGate>
                <Header />

                <ChatListScreen />

                <Footer />
              </AuthGate>
            }
          />
          <Route
            path="/event/:sid/chat"
            element={
              <AuthGate>
                <ChatScreen />
              </AuthGate>
            }
          />
          <Route
            path="/event/:id"
            element={
              <>
                {isAuthenticated && <Header />}
                <EventScreen />
                {isAuthenticated && <Footer />}
              </>
            }
          />
          <Route
            path="/profile/:id"
            element={
              <AuthGate>
                <Header />
                <NewProfileScreen />
                <Footer />
              </AuthGate>
            }
          />
          <Route
            path="/profile"
            element={
              <AuthGate>
                <Header />
                <NewProfileScreen />
                <Footer />
              </AuthGate>
            }
          />
          <Route
            path="/settings"
            element={
              <AuthGate>
                <Header />
                <SettingsScreen />
                <Footer />
              </AuthGate>
            }
          />
          <Route
            path="/categories"
            element={
              <AuthGate>
                <Header />
                <CategoriesScreen />
                <Footer />
              </AuthGate>
            }
          />
          <Route
            path="/notifications"
            element={
              <AuthGate>
                <Header />
                <NotificationsScreen />
                <Footer />
              </AuthGate>
            }
          />
          <Route
            path="/shop"
            element={
              <AuthGate>
                <Header />
                <ShopScreen />
                <Footer />
              </AuthGate>
            }
          />
          <Route
            path="/monition"
            element={
              <AuthGate>
                <MonitionScreen />
              </AuthGate>
            }
          />
          <Route
            path="/ban"
            element={
              <AuthGate>
                <BanScreen />
              </AuthGate>
            }
          />

          <Route
            path="/referral"
            element={
              <AuthGate>
                <ReferralScreen />
              </AuthGate>
            }
          />
        </Routes>
      )}
    </AppContext.Provider>
  )
}

function CreateEventSwitch() {
  const { createEventMode } = useParams()

  switch (createEventMode) {
    case 'wizard':
      return (
        <AuthGate>
          <CreateEventScreen />
        </AuthGate>
      )

    case 'chat-bot':
      return (
        <AuthGate>
          <CreateEventChatBot />
        </AuthGate>
      )

    default:
      return <></>
  }
}

export default App
