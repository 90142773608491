import axios, { AxiosInstance } from 'axios'

interface UnsplashPhotoUrls {
  raw: string
  full: string
  regular: string
  small: string
  thumb: string
  small_s3: string
}

interface UnsplashPhoto {
  id: string
  description: string | null
  urls: UnsplashPhotoUrls
  user: { name: string; links: { html: string } }
  links: { download_location: string }
  // Aggiungi altre proprietà se necessario
}

class UnsplashService {
  private static instance: UnsplashService
  private axiosInstance: AxiosInstance
  private readonly baseUrl: string = 'https://api.unsplash.com'

  private constructor() {
    const accessKey = process.env.REACT_APP_UNSPLASH_ACCESS_KEY
    if (!accessKey) {
      throw new Error("UNSPLASH_ACCESS_KEY non è definita nelle variabili d'ambiente.")
    }

    this.axiosInstance = axios.create({
      baseURL: this.baseUrl,
      headers: {
        Authorization: `Client-ID ${accessKey}`,
      },
    })
  }

  public static getInstance(): UnsplashService {
    if (!UnsplashService.instance) {
      UnsplashService.instance = new UnsplashService()
    }
    return UnsplashService.instance
  }

  //https://unsplash.com/documentation#get-a-random-photo
  public async getRandomPhoto(query: string): Promise<UnsplashPhoto> {
    try {
      const params = {
        query,
        orientation: 'landscape',
      }

      const response = await this.axiosInstance.get<UnsplashPhoto>('/photos/random', { params })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          throw new Error(`Nessuna foto trovata per il termine di ricerca "${query}".`)
        }
      }
      console.error('Errore in getRandomPhoto:', error)
      throw error
    }
  }

  //https://help.unsplash.com/en/articles/2511258-guideline-triggering-a-download
  public async triggerDownload(downloadUrl: string): Promise<void> {
    try {
      const response = await this.axiosInstance.get(downloadUrl)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          throw new Error(`Unsplash: Download non riuscito`)
        }
      }
      console.error('Errore in triggerDownload:', error)
      throw error
    }
  }
}

export default UnsplashService.getInstance()
