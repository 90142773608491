import { AvatarBadge, Box, Button, Text, Textarea, useDisclosure } from '@chakra-ui/react'
import { FormbitContextProvider, useFormbitContext } from '@radicalbit/formbit'
import { UploadIcon } from 'components/shared/icons/icons'
import InputField from 'components/shared/input/input.component'
import UserAvatarEditor from 'components/shared/user-avatar-editor/user-avatar-editor'
import UserAvatar from 'components/shared/user-avatar/user-avatar'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { genderDictionary } from 'costants/genders'
import { selectorAuth } from 'features/auth/auth.slice'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import schema, { Schema } from './schema'
import useHandleAvatarUpload from './use-handle-avatar-upload'
import useHandleOnDelete from './use-handle-on-delete'
import useHandleOnSubmit from './use-handle-on-submit'

const initialValues: Schema = {
  firstName: '',
  lastName: '',
  bioDescription: '',
  gender: '',
}

const SettingsScreen = () => {
  return (
    <FormbitContextProvider<Schema> initialValues={initialValues} schema={schema}>
      <SettingsScreenInner />
    </FormbitContextProvider>
  )
}

const SettingsScreenInner = () => {
  useInitializeForm()
  return (
    <Wrapper>
      <UserAvatarField />

      <FirstNameField />

      <LastNameField />

      <BiographyField />

      <GenderField />

      <BirthDateField />

      <EmailField />

      <ActionButtons />
    </Wrapper>
  )
}

const UserAvatarField = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { handleAvatarUpload } = useHandleAvatarUpload(onClose)

  const { user } = useSelector(selectorAuth)
  const avatar = user?.avatar

  const { form } = useFormbitContext<Schema>()
  const firstName = form?.firstName ?? ''
  const lastName = form?.lastName ?? ''
  const completeName = `${firstName.charAt(0)} ${lastName.charAt(0)}`

  return (
    <Box alignSelf="center" mb="30px">
      <UserAvatarEditor {...{ isOpen, onOpen, onClose }} onUpload={handleAvatarUpload} />
      <UserAvatar size="2xl" name={completeName} src={avatar} onClick={onOpen}>
        <AvatarBadge bg="brand.500" boxSize="0.9em" borderColor="brand.500" borderWidth={1}>
          <UploadIcon boxSize={5} />
        </AvatarBadge>
      </UserAvatar>
    </Box>
  )
}

const FirstNameField = () => {
  const { form, write } = useFormbitContext<Schema>()
  const firstName = form?.firstName ?? ''

  const handleOnChange = ({ target: { value } }) => {
    write('firstName', value)
  }

  return (
    <Box mb="30px">
      <InputField placeholder="Nome" onChange={handleOnChange} required value={firstName} />
    </Box>
  )
}

const LastNameField = () => {
  const { form, write } = useFormbitContext<Schema>()
  const lastName = form?.lastName ?? ''

  const handleOnChange = ({ target: { value } }) => {
    write('lastName', value)
  }

  return (
    <Box mb="30px">
      <InputField placeholder="Cognome" onChange={handleOnChange} required value={lastName} />
    </Box>
  )
}

const BiographyField = () => {
  const { form, write } = useFormbitContext<Schema>()
  const bioDescription = form?.bioDescription ?? ''
  const maxLength = 150

  const handleOnChange = ({ target: { value } }) => {
    write('bioDescription', value)
  }

  return (
    <Box mb="30px">
      <Textarea
        fontSize="14px"
        variant="solid"
        resize="vertical"
        value={bioDescription}
        px="15px"
        py="20px"
        minHeight="115px"
        backgroundColor="#eeeeee"
        placeholder="Racconta qualcosa di unico su di te"
        onChange={handleOnChange}></Textarea>
      <Text fontSize="xs" textAlign="right" color={bioDescription?.length > maxLength ? 'red' : ''}>
        {bioDescription?.length} / {maxLength}
      </Text>
    </Box>
  )
}

const GenderField = () => {
  const { user } = useSelector(selectorAuth)
  const gender = user?.gender ?? ''

  return (
    <Box mb="30px">
      <Text pb="5px" fontWeight="semibold" color="#465362">
        Genere
      </Text>
      <Text ml="2">{genderDictionary[gender]}</Text>
    </Box>
  )
}

const BirthDateField = () => {
  const { user } = useSelector(selectorAuth)
  const birthdate = user?.birthdate

  const birthdateFormatted = birthdate ? moment(birthdate).format('DD MMMM YYYY') : ''

  return (
    <Box mb="30px">
      <Text pb="5px" fontWeight="semibold" color="#465362">
        Data di nascita
      </Text>
      <Text ml="2">{birthdateFormatted}</Text>
    </Box>
  )
}

const EmailField = () => {
  const { user } = useSelector(selectorAuth)
  const email = user?.email ? user.email : ''

  return (
    <Box mb="30px">
      <Text pb="5px" fontWeight="semibold" color="#465362">
        Email
      </Text>
      <Text ml="2">{email}</Text>
    </Box>
  )
}

const ActionButtons = () => {
  return (
    <>
      <SaveButton />
      <DeleteButton />
    </>
  )
}

const SaveButton = () => {
  const { handleOnSubmit, isSubmitDisabled } = useHandleOnSubmit()
  return (
    <Button width="90%" alignSelf="center" mb="30px" isDisabled={isSubmitDisabled} onClick={handleOnSubmit}>
      Salva
    </Button>
  )
}

const DeleteButton = () => {
  const { handleOnDelete } = useHandleOnDelete()
  return (
    <Button variant="link" fontSize={12} alignSelf="center" onClick={handleOnDelete} mt="30px" mb="30px">
      Elimina account
    </Button>
  )
}

const useInitializeForm = () => {
  const { user } = useSelector(selectorAuth)
  const firstName = user?.firstName
  const lastName = user?.lastName
  const bioDescription = user?.bioDescription
  const gender = user?.gender

  const { initialize } = useFormbitContext()

  useEffect(() => {
    initialize({ firstName, lastName, bioDescription, gender })
  }, [bioDescription, firstName, initialize, lastName, gender])
}

export default SettingsScreen
