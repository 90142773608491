import Api from "@src/services/api/api.service"
import { EventDTO } from "@src/typings/database"
import { AxiosError } from "axios"
import { useQuery, UseQueryOptions } from "react-query"

const useGetEventById = ({ eventId }: { eventId: string | undefined }, options: UseQueryOptions<unknown, AxiosError, EventDTO> = {}) => {
  return useQuery({
    queryKey: [`getEvent${eventId}`],
    queryFn: async () => {
      const response = await Api.event(eventId!)
      return response.data
    },
    enabled:!!eventId,
    ...options
  })
}


export default useGetEventById