import { Box, Img } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import useGetBannersQuery from './use-get-banner-query'
import { Banner } from './types'
import { FirebaseAnalytics } from '@capacitor-firebase/analytics'

export const AdvBanner = () => {
  const { isSuccess, data } = useGetBannersQuery()

  if (!isSuccess || data?.length < 1) {
    return <></>
  }

  return <AdvBannerInner />
}

const AdvBannerInner = () => {
  const currentBanner = useGetCurrentBanner()
  const origineFile = currentBanner?.origineFile
  const linkDestinazione = currentBanner?.linkDestinazione
  const nomeBrand = currentBanner?.nomeBrand

  const handleOnClick = () => {
    FirebaseAnalytics.logEvent({ name: 'adv_banner_click', params: { nomeBrand, linkDestinazione } })
    window.open(linkDestinazione)
  }

  if (!origineFile) {
    return <></>
  }

  return (
    <Box position="relative" mr="-5" ml="-5" onClick={handleOnClick} cursor="pointer">
      <Box w="100%" left="0" h="140px" position="absolute" overflow="hidden">
        <Box pos="relative" maxW={540} m="auto">
          <Img src={origineFile} alt={nomeBrand} />
          {/* "Adv" in top right */}
          <Box position="absolute" right="0" top="0" bg="black" color="white" p="5px" fontSize="10px">
            Adv
          </Box>
        </Box>
      </Box>

      <Box h="140px"></Box>
    </Box>
  )
}

const useGetCurrentBanner = (): Banner | undefined => {
  const [currentBanner, setCurrentBanner] = useState<Banner>()

  const { data } = useGetBannersQuery()
  const count = data.length

  const currentIndex = useRef<number>(generateRandomNumber(count))

  useEffect(() => {
    setCurrentBanner(data[currentIndex.current])
    currentIndex.current = (currentIndex.current + 1) % count

    if (count > 1) {
      const intervall = setInterval(() => {
        setCurrentBanner(data[currentIndex.current])
        currentIndex.current = (currentIndex.current + 1) % count
      }, 5000)

      return () => {
        clearInterval(intervall)
      }
    }
  }, [count, data])

  return currentBanner
}

/**
 *
 * @param limit Max int you want to get
 * @returns Random number between 0 and limit - 1
 */
const generateRandomNumber = (limit: number): number => Math.floor(Math.random() * limit + 1) - 1
