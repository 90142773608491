import { Box, Flex, Textarea } from '@chakra-ui/react'
import { ReactComponent as CalendarIco } from 'assets/calendar-ico.svg'
import { ReactComponent as PlaneIco } from 'assets/plane-ico.svg'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ChatPlusMenu from './chat-plus-menu'
import { useChatContext } from './context/chat-context'
import useSendTextMessageMutation from './hooks/use-send-text-message-mutation'
import useAutosizeTextArea from './hooks/useAutosizeTextArea'
import { Capacitor } from '@capacitor/core'

const isNative = Capacitor.isNativePlatform()

function ChatInputText() {
  const { sid = '' } = useParams() // FIXME: default value to emtpy string otherwise TS complains
  const { setReply, reply, chatRef, textAreaInputRef } = useChatContext()
  const [message, setMessage] = useState<string>('')

  useAutosizeTextArea(textAreaInputRef.current,message)

  const sendMessage = useSendTextMessageMutation({
    onSuccess: () => {
      setMessage('')
      setReply(undefined)

      textAreaInputRef.current?.focus()
      textAreaInputRef.current?.click()

      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current?.scrollHeight
      }
    },
  })

  const handleOnInput = ({ currentTarget: { value } }) => {
    setMessage(value)
  }

  const handleOnKeyDown = event => {
    if(isNative){
      nativeHandleOnKeyDown(event)
    }else{
      webHandleOnKeyDown(event)
    }
  }

  const nativeHandleOnKeyDown = (event) => {
    if(event.key === 'Enter') {
      event.preventDefault();
      setMessage(`${message}\n`)
    }
  }

  const webHandleOnKeyDown = (event) => {
    if(event.shiftKey && event.key === 'Enter'){
      event.preventDefault();
      setMessage(`${message}\n`)
    }else if (event.key === 'Enter') {
      sendMessage.mutate({ sid, message, reply })
    }
  }

  const handleOnSendMessage = () => {
    sendMessage.mutate({ sid, message, reply })
  }

  

  return (
    <Flex 
      alignItems="end" 
      px="1rem" 
      gap="1rem" 
      py="1rem" 
      width="100%" 
      position="relative" 
      left="0" 
      bottom="0"
      >
      <ChatPlusMenu  />
      <Textarea 
          resize="none"
          variant="solid"
          rows={1}
          ref={textAreaInputRef}
          placeholder="Messaggio"
          value={message}
          maxHeight="10rem"
          px="20px"
          py="15px"
          fontSize="14px"
          onInput={handleOnInput}
          onKeyDown={handleOnKeyDown}
          isDisabled={sendMessage.isLoading}
      >
      </Textarea>
      
      <Box py="0.7rem" color="brand.500" cursor="pointer" fontSize="1.5rem">
        <Link to="/event/create/wizard">
          <CalendarIco />
        </Link>
      </Box>

      <Box py="0.7rem" color="brand.500" cursor="pointer" fontSize="1.5rem" onClick={handleOnSendMessage}>
        <PlaneIco />
      </Box>
    </Flex>
  )
}

export default ChatInputText
