import unsplashService from '@src/services/unsplash.service'
import { useCallback, useState } from 'react'

export type UnsplashImage = { url: string; name: string; portfolioUrl: string; downloadUrl: string }

export const useUnsplashImage = () => {
  const [unsplashPhoto, setUnsplashPhoto] = useState<UnsplashImage | null>(null)

  const getRandomImage = useCallback(async (queryString: string): Promise<string | undefined> => {
    try {
      const {
        urls: { regular: url },
        user: {
          name,
          links: { html: portfolioUrl },
        },
        links: { download_location: downloadUrl },
      } = await unsplashService.getRandomPhoto(queryString)
      setUnsplashPhoto({ url, name, portfolioUrl, downloadUrl })
      return url
    } catch {
      setUnsplashPhoto(null)
    }
  }, [])

  const triggerDownload = useCallback(async (): Promise<boolean> => {
    if (!unsplashPhoto) return false

    try {
      await unsplashService.triggerDownload(unsplashPhoto.downloadUrl)
      return true
    } catch {
      return false
    }
  }, [unsplashPhoto])

  return { unsplashPhoto, getRandomImage, triggerDownload }
}
