import { CreateToastFnReturn, useToast } from '@chakra-ui/react'
import { refreshUser } from '@features/auth/auth.actions'
import { useAppDispatch } from '@features/index'
import Api from '@src/services/api/api.service'
import resizeFile from '@src/utils/resize-file'

const useHandleAvatarUpload = onClose => {
  const toast = useToast()
  const dispatch = useAppDispatch()

  const handleAvatarUpload = async (file: any) => {
    const body = new FormData()
    body.set('avatar', (await resizeFile(file)) as any)

    try {
      await Api.editAvatar(body)

      showSuccessNotification(toast)

      dispatch<any>(refreshUser())
      onClose()
    } catch (e: any) {
      showErrorNotification(e, toast)
    }
  }

  return { handleAvatarUpload }
}

const showErrorNotification = (e: any, toast: CreateToastFnReturn) => {
  if (e?.response?.data?.message && typeof e?.response?.data?.message === 'string') {
    toast({
      title: e.response.data.message || 'Error',
      status: 'error',
      duration: 2500,
      isClosable: true,
      position: 'bottom',
    })
  }
}

const showSuccessNotification = (toast: CreateToastFnReturn) => {
  toast({
    title: 'Foto profilo modificata con successo',
    status: 'success',
    duration: 2500,
    isClosable: true,
    position: 'top',
  })
}

export default useHandleAvatarUpload
